import Marco from '../components/marco'
import {css} from '@emotion/core'
import Container from '@material-ui/core/Container'
import Minipagina from '../components/minipagina'
import Minipaginas from '../components/minipaginas'
import Titular from '../components/titular'
import EspaciadorVertical from '../components/espaciador-vertical'
import React from 'react'
import FormularioDeContacto from '../components/formulario-de-contacto'

const feliz = css`
  margin: 0 0.5rem
`

// #region Asesoramiento
export default function Asesoramiento() {
  const handleClose = () => {}
  // #region Render
  return (
    <Marco>
      <Container>
        <EspaciadorVertical altura={8} />
        <Minipaginas>
          <Minipagina>
            <EspaciadorVertical altura={32} />
            <Titular texto="Planifico tu viaje" />
            <div style={{paddingRight: '2rem'}}>
              <p>
                ¿Te gustaría conocer Roma de manera no tradicional? ¿Caminarla y descubrir lugares
                encantadores? ¡Pero te falta tiempo para organizar tu viaje!
              </p>
              <p>
                Y sí, buscar toda la información lleva mucho tiempo. Y qué decir de planificar tus recorridos.
              </p>
              <p>
                ¡Ahí estoy yo para ayudarte!
                <span aria-label="smile" role="img" css={feliz}>
                  🙂
                </span>
                Mi trabajo consiste en proponerte recorridos personalizados para hacer caminado (eventualmente
                en bici). Me decís tus gustos, tus días disponibles, tus necesidades y … ¡Listo! Recibís mis
                propuestas junto con información que te pueda resultar útil elaborada de manera novedosa.
              </p>
              <p>
                Contáctame llenando el siguiente formulario y coordinamos una charla por whatsapp, skype,
                teléfono o café mediante. El asesoramiento lo envío por email. No necesitás moverte de tu
                casa.
              </p>
            </div>
          </Minipagina>
          <Minipagina palida>
            <EspaciadorVertical altura={36} />
            <Titular texto="Contáctame" color="primary" />
            <FormularioDeContacto asesoramiento handleClose={handleClose}/>
            <EspaciadorVertical altura={24} />
          </Minipagina>
        </Minipaginas>
        <EspaciadorVertical altura={48} />
      </Container>
    </Marco >
  )
  // #endregion
}
// #endregion
